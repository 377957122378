"use client"

import GetCart from '@/services/bigcommerce/cart/GetCart';
import UpdateLastCartItem from '@/services/bigcommerce/cart/UpdateLastCartItem';
import UpdateCartItem from '@/services/bigcommerce/cart/UpdateCartItem';
import UpdateCartItemQty from '@/services/bigcommerce/cart/UpdateCartItemQty';
import AddCoupon from '@/services/bigcommerce/cart/AddCoupon';
import DeleteCoupon from '@/services/bigcommerce/cart/DeleteCoupon';
import UpdateCustomer from '@/services/bigcommerce/cart/UpdateCustomer';
import DeleteCartItem from '@/services/bigcommerce/cart/DeleteCartItem';
import GetCartById from '@/services/bigcommerce/cart/GetCartById';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AddToCart } from '@/components/applications/cart-checkout/_contexts/CartWidgetContext';
import { useContext } from 'react';
import { GlobalContext } from '@/contexts/GlobalContext';
import { LocalStorage } from '@/services/LocalStorage.service';

/**
 * Global Add to Cart Hook
 * @returns mutation method, cart, and cart event state
 */

export function useCart(){
    const [ global, dispatch ] = useContext(GlobalContext)
    const queryClient = useQueryClient()

    const itemMutation = useMutation({
            mutationFn: ({ type, id, productBody, cartId, productId, code, customerId, buyNow }) => {
                switch(type){
                    case "getCartById":
                        return GetCartById(id);
    
                    case "add": 
                        return AddToCart(productBody, buyNow);                        
    
                    case "remove": 
                        return DeleteCartItem(productId, cartId);
    
                    case "updateQty":
                        return UpdateCartItemQty(id, productBody, cartId);
    
                    case "addCoupon":
                        return AddCoupon({ coupon_code: code }, cartId);
    
                    case "removeCoupon":
                        return  DeleteCoupon(code, cartId);
    
                    case "updateItem":
                        return UpdateCartItem(productBody, id, cartId);
    
                    case "updateLastItem":
                        return UpdateLastCartItem(productBody, id, cartId);
    
                    case "updateCustomer":
                        return UpdateCustomer(cartId, { customer_id: parseInt(customerId) })
    
                    default: return null;
                }
            },
            mutationKey: "cart",
            onSuccess: ( response, variables ) => {
                /* 
                    preventing full cart mutation because BigCommerce 
                    doesn't bring back options for the coupon response
               
                    Also, we need to merge the cart back in to the object for these 
                    methods as BC changes the shape of the cart response 
                */

                let responseData = null;

                switch(variables.type){
                    case "addCoupon":
                    case "removeCoupon":
                        responseData = variables?.prevCart && {
                            ...variables?.prevCart,
                            ...response?.data,
                            line_items: {
                                ...variables?.prevCart?.line_items,
                            }
                        };

                        break;

                    default: responseData = response?.data;
                }


                if( responseData || (response?.id && response?.line_items?.physical_items.length) ){
                    const cartResponse = responseData ?? response;

                    LocalStorage.setStorage({ key: "GRS_cart", data: cartResponse, type: "local" })

                    dispatch({
                        type: "setCart",
                        data: cartResponse
                    })

                }else{
                    LocalStorage.remove("GRS_cart")

                    dispatch({
                        type: "setCart",
                        data: null
                    })

                    window.location.reload()
                }
                
            }
        }
    )


    const { data: cartData, isLoading, isError, isSuccess } = useQuery({
        queryKey: ["cart"],
        queryFn: () => GetCart(),
    })


    return{
        itemMutation,
        cartData,
        isLoading,
        isError,
        isSuccess
    }
}